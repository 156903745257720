<template>
  <div v-if="isAuthLoggedIn()">
    <slot />
  </div>
</template>

<script setup lang="ts">
const { isAuthLoggedIn } = useMenuModel()

const localePath = useLocalePath()
if (!isAuthLoggedIn()) {
  useRouter().replace(
    localePath({ path: '/auth/login', query: { callbackUrl: useRoute().path } })
  )
}
</script>
